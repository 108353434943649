var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('base-header',{staticClass:"header pb-8 pt-5 pt-lg-8 d-flex align-items-center"},[_c('span',{staticClass:"mask bg-gradient-success opacity-8"}),_c('div',{staticClass:"container-fluid d-flex align-items-center"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12"},[_c('h1',{staticClass:"display-3 text-white"},[_vm._v(_vm._s(_vm.t("patients")))])])])])]),_c('div',{staticClass:"container-fluid mt--7"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12"},[_c('card',{attrs:{"shadow":"","type":"secondary"}},[(_vm.$wait.is('fetchPatients') || _vm.$wait.is('deletePatient'))?_c('base-loader'):_vm._e(),_c('div',{staticClass:"bg-white border-0",attrs:{"slot":"header"},slot:"header"},[_c('div',{staticClass:"row align-items-center"},[_c('div',{staticClass:"col-12 col-md-8"}),_c('div',{staticClass:"col-12 col-md-4 text-right"},[_c('router-link',{attrs:{"to":"/new-patient"}},[(_vm.user.level !== 'VIEWER')?_c('base-button',{attrs:{"type":"primary","icon":"ni ni-fat-add"}},[_vm._v(" "+_vm._s(_vm.t("new_patient"))+" ")]):_vm._e()],1)],1)])]),[_c('vue-good-table',{attrs:{"columns":_vm.columns,"rows":_vm.rows,"search-options":{
                enabled: true,
                skipDiacritics: false,
                placeholder: _vm.t('search_patient'),
                externalQuery: _vm.searchTerm,
              },"pagination-options":{
                enabled: true,
                mode: 'records',
                perPage: 10,
                position: 'bottom',
                nextLabel: _vm.t('next'),
                prevLabel: _vm.t('prev'),
                rowsPerPageLabel: _vm.t('per_page'),
                ofLabel: 'of',
                pageLabel: _vm.t('page'),
                allLabel: _vm.t('all'),
              },"fixed-header":true,"line-numbers":true,"row-style-class":_vm.rowStyleClassFn},scopedSlots:_vm._u([{key:"table-row",fn:function(props){return [(props.column.field == 'actions')?_c('span',[_c('div',[_c('router-link',{staticClass:"mr-2",attrs:{"to":("/patients/" + (props.row.id))}},[_c('base-button',{attrs:{"type":"primary","icon":"ni ni-ruler-pencil","size":"sm"}})],1),(!['VIEWER', 'EDITOR'].includes(_vm.user.level))?_c('base-button',{attrs:{"outline":"","type":"warning","icon":"ni ni-fat-remove","size":"sm","disabled":props.row.approvement == 1},on:{"click":function($event){return _vm.openDeleteConfirmModal(props.row.id)}}}):_vm._e()],1)]):_c('span',[_vm._v(" "+_vm._s(props.formattedRow[props.column.field])+" ")])]}}])})]],2)],1)])]),_c('delete-confirm-modal',{attrs:{"modalStatus":_vm.patientDeleteModal,"id":_vm.tempPatientId},on:{"update:modalStatus":function($event){_vm.patientDeleteModal=$event},"update:modal-status":function($event){_vm.patientDeleteModal=$event},"confirm":_vm.deletePatient}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }