<template>
  <div>
    <base-header class="header pb-8 pt-5 pt-lg-8 d-flex align-items-center">
      <!-- Mask -->
      <span class="mask bg-gradient-success opacity-8"></span>
      <!-- Header container -->
      <div class="container-fluid d-flex align-items-center">
        <div class="row">
          <div class="col-12">
            <h1 class="display-3 text-white">{{ t("patients") }}</h1>
          </div>
        </div>
      </div>
    </base-header>

    <div class="container-fluid mt--7">
      <div class="row">
        <div class="col-12">
          <card shadow type="secondary">
            <base-loader
              v-if="$wait.is('fetchPatients') || $wait.is('deletePatient')"
            />
            <div slot="header" class="bg-white border-0">
              <div class="row align-items-center">
                <div class="col-12 col-md-8"></div>
                <div class="col-12 col-md-4 text-right">
                  <router-link :to="`/new-patient`">
                    <base-button
                      type="primary"
                      icon="ni ni-fat-add"
                      v-if="user.level !== 'VIEWER'"
                    >
                      {{ t("new_patient") }}
                    </base-button>
                  </router-link>
                </div>
              </div>
            </div>
            <template>
              <vue-good-table
                :columns="columns"
                :rows="rows"
                :search-options="{
                  enabled: true,
                  skipDiacritics: false,
                  placeholder: t('search_patient'),
                  externalQuery: searchTerm,
                }"
                :pagination-options="{
                  enabled: true,
                  mode: 'records',
                  perPage: 10,
                  position: 'bottom',
                  nextLabel: t('next'),
                  prevLabel: t('prev'),
                  rowsPerPageLabel: t('per_page'),
                  ofLabel: 'of',
                  pageLabel: t('page'),
                  allLabel: t('all'),
                }"
                :fixed-header="true"
                :line-numbers="true"
                :row-style-class="rowStyleClassFn"
              >
                <template slot="table-row" slot-scope="props">
                  <span v-if="props.column.field == 'actions'">
                    <div>
                      <router-link
                        :to="`/patients/${props.row.id}`"
                        class="mr-2"
                      >
                        <base-button
                          type="primary"
                          icon="ni ni-ruler-pencil"
                          size="sm"
                        ></base-button>
                      </router-link>
                      <base-button
                        outline
                        type="warning"
                        icon="ni ni-fat-remove"
                        size="sm"
                        :disabled="props.row.approvement == 1"
                        @click="openDeleteConfirmModal(props.row.id)"
                        v-if="!['VIEWER', 'EDITOR'].includes(user.level)"
                      ></base-button>
                    </div>
                  </span>
                  <span v-else>
                    {{ props.formattedRow[props.column.field] }}
                  </span>
                </template>
              </vue-good-table>
            </template>
          </card>
        </div>
      </div>
    </div>
    <delete-confirm-modal
      :modalStatus.sync="patientDeleteModal"
      :id="tempPatientId"
      @confirm="deletePatient"
    />
  </div>
</template>
<script>
import { mapState } from "vuex";
import DeleteConfirmModal from "../components/Modals/deleteConfirmModal";
export default {
  name: "patients",
  data() {
    return {
      patientDeleteModal: false,
      tempPatientId: null,
      columns: [
        {
          label: this.t("patient_initials"),
          field: "name",
          filterOptions: {
            enabled: true,
            skipDiacritics: false,
          },
          formatFn: (val, row) => {
            return this.$options.filters.patientName(row);
          },
        },
        {
          label: this.t("patient_no"),
          field: "patient_no",
          type: "string",
          filterOptions: {
            enabled: true,
            skipDiacritics: false,
          },
        },
        {
          label: this.t("randomization_randomization_no"),
          field: "randomization_no",
          type: "number",
          filterOptions: {
            enabled: true,
            skipDiacritics: false,
          },
        },
        {
          label: this.t("date"),
          field: "date",
          type: "date",
          dateInputFormat: "yyyy-MM-dd",
          dateOutputFormat: "dd.MM.yyyy",
          filterOptions: {
            enabled: true,
            skipDiacritics: false,
          },
        },
        {
          label: this.t("center"),
          field: "center_name",
          filterOptions: {
            enabled: true,
          },
        },
        {
          label: this.t("researcher"),
          field: "user_full_name",
          filterOptions: {
            enabled: true,
          },
        },
         {
          label: this.t("status"),
          field: "eligibility_criteria",
          filterOptions: {
            enabled: false,
          },
           formatFn: (val) => {
            return val? 'Randomized': 'Screen Failure';
          },
        },
        // {
        //   label: this.t("status"),
        //   field: "approvement",
        //   filterOptions: {
        //     enabled: false,
        //   },
        //   sortFn: (x, y) => {
        //     return x < y ? -1 : x > y ? 1 : 0;
        //   },
        //   formatFn: (val) => {
        //     return val ? this.t("approved") : this.t("pending");
        //   },
        // },
        {
          label: this.t("actions"),
          field: "actions",
        },
      ],
      rows: [],
    };
  },
  components: {
    DeleteConfirmModal,
  },
  computed: {
    ...mapState({
      searchTerm: (state) => state.searchTerm,
      user: (state) => state.auth.user,
    }),
  },
  methods: {
    getPatients() {
      this.$wait.start("fetchPatients");
      this.$store
        .dispatch("patient/FETCH_PATIENTS")
        .then((patients) => {
          this.rows = patients.map((_p) => {
            return {
              ..._p,
              name: `${_p.patient_initial_1} ${
                _p.patient_initial_2 ? _p.patient_initial_2 : "-"
              } ${_p.patient_initial_3}`.toUpperCase(),
            };
          });
        })
        .catch((err) => {
          this.showError(err);
        })
        .finally(() => {
          this.$wait.end("fetchPatients");
        });
    },
    openDeleteConfirmModal(id = null) {
      this.tempPatientId = id;
      this.patientDeleteModal = true;
    },
    deletePatient() {
      this.$wait.end("deletePatient");
      this.$store
        .dispatch("patient/DELETE_PATIENT", this.tempPatientId)
        .then(() => {
          this.showSuccess(this.t("message_deleted"));
          this.getPatients();
        })
        .catch((err) => {
          this.showError(err);
        })
        .finally(() => {
          this.$wait.end("deletePatient");
        });
    },
    rowStyleClassFn(row) {
      return row.approvement ? "row-green" : "";
    },
  },
  created() {
    this.getPatients();
    if (this.user.level === "MASTER") {
      this.columns.splice(3, 0, {
        label: this.t("randomization_lot_number"),
        field: "randomization_lot_number",
        filterOptions: {
          enabled: true,
          skipDiacritics: false,
        },
      });
    }
  },
};
</script>
<style lang="scss">
.row-green {
  background-color: #2dce89;
  td {
    color: #fff !important;
  }
}
</style>
